/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
"use strict";

import "./styles";

import domReady from "domready";

domReady(function() {
    const $form = $(".form-signin");
    const $email = $form.find('input[type="text"]');
    const $password = $form.find('input[type="password"]');
    const $button = $form.find(".btn-login");

    const update_login_button = function() {
        if ($email.val() !== "" && $password.val() !== "") {
            return $button.addClass("active");
        } else {
            return $button.removeClass("active");
        }
    };

    $email.on("input", update_login_button);
    $password.on("input", update_login_button);
    update_login_button();
    return mixpanel.track_orig("App: viewed login page");
});
